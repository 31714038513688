import React from "react";
import VideoChatPage from "./VideoChatPage";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";



const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <VideoChatPage />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
