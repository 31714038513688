import React, { useEffect } from "react";
import "./App.css";
import { locales } from "./constants";
import uitoolkit from "@zoom/videosdk-ui-toolkit";
import "@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css";

const Room = ({ roomId, token, username, lang, handleLogout }) => {
  const config = {
    sessionName: roomId,
    userName: username,
    features: ["video", "audio", "users",],
    videoSDKJWT: token,
  };
  var sessionContainer;

  useEffect(() => {
    sessionContainer = document.getElementById("sessionContainer");
    joinSession();
  });

  function joinSession() {
    uitoolkit.joinSession(sessionContainer, config);
    uitoolkit.onSessionJoined(sessionJoined);
    uitoolkit.onSessionClosed(sessionClosed);
  }

  var sessionClosed = () => {
    uitoolkit.closeSession(sessionContainer);
    handleLogout();
  };

  var sessionJoined = () => {
    const observer = new MutationObserver(handleClassAppearance);
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  };

  function handleClassAppearance(mutationsList, observer) {
    if (lang != "fr") {
      return null
    }
    for (let mutation of mutationsList) {
      if (mutation.type === "childList") {
        // Check added nodes
        for (let node of mutation.addedNodes) {
          // if (node.nodeType === 1) {
          // } 
          if (
            node.nodeType === 1 &&
            node.tagName.toLowerCase() === "app-audiokit"
          ) {
              translateAudioPopUp(node);
          } else if (node.nodeType === 1 && node.classList.contains('mat-menu-below')) {
            translateLeavePopUp(node);
          }
        }
      }
    }
  }

  function translateAudioPopUp(node) {
    node.getElementsByClassName("mat-mdc-dialog-title")[0].textContent =
      locales.videoPopUpActionRequired?.[lang];
    node
      .getElementsByClassName("mat-mdc-dialog-content")[0]
      .getElementsByTagName("p")[0].textContent =
      locales.videoPopUpAction?.[lang];
    node
      .getElementsByClassName("mat-mdc-dialog-actions")[0]
      .getElementsByClassName("mdc-button__label")[0].textContent =
      locales.videoPopUpSubmit?.[lang];
  }

  function translateLeavePopUp(node) {
    node
    .getElementsByClassName("mat-mdc-menu-item-text")[0].textContent = locales.logout?.[lang];
  }

  return (
    <div className="room">
      <main>
        <h2>Room: {roomId}</h2>
        <div id="sessionContainer"></div>
      </main>
    </div>
  );
};

export default Room;
