import React, { useState, useCallback } from "react";
import { locales } from "./constants";
import Lobby from "./Lobby";
import Room from "./Room";

const VideoChat = ({ roomId, lang }) => {
  const [username, setUsername] = useState("");
  const [token, setToken] = useState(null);
  const random = (Math.random() + 1).toString(36).substring(7);

  const handleUsernameChange = useCallback((event) => {
    setUsername(event.target.value);
  }, []);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/api/rooms/token?roomId=${roomId}&identifier=${random}${username}`,
        {
          method: 'POST',
          body: JSON.stringify({
            roomId:  roomId,
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      if (res.ok) {
        const data = await res.json()
        setToken(data.token);
      } else {
        alert(locales.forbiddenSession?.[lang])
      }

    },
    [roomId, username]
  );

  const handleLogout = useCallback((_) => {
    setToken(null);
  }, []);

  let render;
  if (token) {
    render = (
      <Room roomId={roomId} username={username} token={token} lang={lang} handleLogout={handleLogout} />
    );
  } else {
    render = (
      <Lobby
        username={username}
        lang={lang}
        roomId={roomId}
        handleUsernameChange={handleUsernameChange}
        handleSubmit={handleSubmit}
      />
    );
  }
  return render;
};

export default VideoChat;
