export const locales = {
  enterRoom: {
    fr: 'Rejoindre',
    en: 'Join'
  },
  name: {
    fr: 'Nom',
    en: 'Name'
  },
  submit: {
    fr: 'Soumettre',
    en: 'Submit'
  },
  logout: {
    fr: 'Terminer',
    en: 'Log out'
  },
  forbiddenSession: {
    fr: 'Accès à la réunion interdit',
    en: 'Access forbidden'
  },
  videoPopUpActionRequired: {
    fr: "Action Requise"
  },
  videoPopUpAction: {
    fr: "Cliquez sur le bouton dessous pour activer l'audio"
  },
  videoPopUpSubmit: {
    fr: "Ok"
  }
}