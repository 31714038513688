import React from "react";
import { locales } from "./constants";

const Lobby = ({ username, lang, handleUsernameChange, handleSubmit }) => {
  return (
    <form className="join" onSubmit={handleSubmit}>
      <h2>{locales.enterRoom?.[lang]}</h2>
      <div>
        <label htmlFor="name">{locales.name?.[lang]}:</label>
        <input
          type="text"
          id="field"
          value={username}
          onChange={handleUsernameChange}
          required
        />
      </div>
      <button type="submit">{locales.submit?.[lang]}</button>
    </form>
  );
};

export default Lobby;
