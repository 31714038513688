import React from "react";
import "./App.css";
import VideoChat from "./VideoChat";
import { useLocation } from "react-router-dom";
// const queryString = require("query-string");

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const VideoChatPage = () => {
  // if (window.location.protocol !== "https:") {
  //   window.location.protocol = "https:";
  // }
  // const locationQuery = useLocation().search
  let query = useQuery();
  const lang = navigator?.language?.split("-")?.[0];
  let roomId = query.get("roomId");
  return (
    <div className="app">
      <header className="act">
        <h1>AGIR PAR VIDEO</h1>
      </header>
      <main>
        <VideoChat roomId={roomId} lang={lang} />
      </main>
      <footer>
        <p>Monsherif</p>
      </footer>
    </div>
  );
};

export default VideoChatPage;
